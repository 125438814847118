<template>
  <div id="conditions" class="m-md">
    <h1 class="modal-title">{{$t('eurofiscalis_workers.register.conditions.title')}}</h1>
    <div class="m-t-md" v-html="$t('eurofiscalis_workers.register.conditions.content')"></div>
  </div>

</template>

<style scoped>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

@Component({
  components : {}
})
export default class Conditions extends mixins(GenericPage) {

  mounted() {
    if(this.$route.query.print) {
      window.print();
    }
  }
  
}
</script>